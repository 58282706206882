
















import coreApiClient from "@/services/apis/coreApiClient";
import Vue from "vue";
import BaseBarcodeScanPage from "./BaseBarcodeScanPage.vue";
export default Vue.extend({
  components: { BaseBarcodeScanPage },
  data() {
    return {
      setting: null,
      prepareHandler: async (barcode) => {
        return await coreApiClient.call("orderFulfillment", "preparePrintMain", {
          barcode,
        });
      },
      actionHandler: async (barcode, result, setting, printerName) => {
        const tags = result.productItem?.productVariant?.product?.tags || [];
        if (!printerName) {
          const printerItem = setting.printerItems.find(({ productTags }) => {
            return tags.some((tag) => productTags?.includes(tag));
          });
          if (!printerItem) {
            throw new Error(`No matched printer found for product tags: ${tags.join(", ")}`);
          }
          printerName = printerItem.name;
        }
        await coreApiClient.callRaw(
          "print",
          "print",
          {
            printerName,
            url: result.printUrl,
            type: "tshirt",
          },
          {
            extraParams: {
              propertyValues: {
                size:
                  result.productItem?.productVariant?.propertyValues?.size ||
                  result.productItem?.productVariant?.propertyValues?.Size,
                color:
                  result.productItem?.productVariant?.propertyValues?.color ||
                  result.productItem?.productVariant?.propertyValues?.Color,
                inkColor: result.productItem?.fulfillmentInkColor || "",
                width:
                  result.productItem?.productVariant?.fulfillmentWidth ||
                  result.productItem?.productVariant?.product?.fulfillmentWidth,
                height:
                  result.productItem?.productVariant?.fulfillmentHeight ||
                  result.productItem?.productVariant?.product?.fulfillmentHeight,
                x:
                  result.productItem?.productVariant?.fulfillmentX ||
                  result.productItem?.productVariant?.product?.fulfillmentX,
                highlight: result.productItem?.productVariant?.fulfillmentHighlight,
                mask: result.productItem?.productVariant?.fulfillmentMask,
              },
            },
          }
        );
        await coreApiClient.callRaw("orderFulfillment", "printMain", {
          barcode,
        });
      },
    };
  },
});
